<template>
  <q-item class="flex column content-strech" dense>
    <q-form @submit="login" >
      <q-input
        v-model="email"
        label="Email"
        type="email"
        required
        class="q-mb-lg"

      />
      <q-input
        v-model="password"
        label="Password"
        type="password"
        required
        class="q-mb-lg"
      />
      <q-btn
        type="submit"
        label="Login"
        color="primary"
        :loading="isLoading"
        :disable="isLoading"
        class="q-mr-sm"
      />
      <q-btn v-if="connect" to="/inscription" color="secondary" >S'inscrire</q-btn>
      <br><br>
      <a href="#" class="text-primary" @click="pass = true">Mot de passe perdu ?</a>

      <q-dialog class="edit-zone" v-model="pass" transition-show="slide-up" transition-hide="slide-down"
          @hide="pass = false">
        <q-card>
          <q-card-section>
            <p class="q-my-none text-h6">Veuillez entrer votre adresse e-mail pour récupérer votre mot de passe.</p>
            <q-form @submit="submitPass" class="q-gutter-md">
              <q-input v-model="resendEmail" label="Email" type="email" required />
              <q-btn @click="pass = false" label="annuler" color="primary" class="q-mt-md" />
              <q-btn type="submit" label="Envoyer" color="primary" class="q-mt-md" />
            </q-form>
          </q-card-section>
        </q-card>
      </q-dialog>
    </q-form>
  </q-item>
</template>

<script>
import {api_POST, formatError, notifyOpts} from '../api'

export default {
  props: {
    redirect: {
      type: String,
      default: null,
    },
    connect: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      email: '',
      password: '',
      isLoading: false,
      pass: false,
      resendEmail: null,
    }
  },
  methods: {
    async login() {
      this.isLoading = true

      console.log( this.$q )

      try {
        await this.$store.dispatch('doLogin', {
          email: this.email,
          password: this.password
        })

        // Rediriger vers la page demandée si redirect est défini
        if (this.$props.redirect !== null) {
          this.$router.push(this.$props.redirect);
        }
      } catch (err) {
        console.log(err.response)

        if(err.response.status === 400) {
          const errors = formatError(err.response.data)
          this.$q.notify({
            ...notifyOpts,
            message: `Erreur lors de la connexion :<br>${errors}`
          })
        }
      } finally {
        this.isLoading = false
      }
    },
    submitPass(){
      this.pass = false
      this.$q.notify({
        type: 'positive',
        message: `Un e-mail de récupération vous a été envoyé.`
      })

      api_POST(
        'dj-rest-auth/password/reset',
        {
          "email": this.resendEmail
        },
        () => {
          console.log('ok resendEmail');
        }
      )
    }
  }
}
</script>
