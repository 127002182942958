/* eslint-disable */

import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from './layouts/Default.vue'
import Menu from 'Const/Menu'
import store from "./store";



async function page_protect(to, from, next){
  if (!store.getters.isLoggedIn) {
    await store.dispatch('fetchUserData')
  }
  // Si l'utilisateur est connecté, charger le composant
  if (store.getters.isLoggedIn) {
    if( store.getters.getUser.profile_completed || store.getters.isAdmin)
    {
      next();
    }
    else if( to.path != '/mon-profile' )
    {
      console.log( 'router', to);
      next({
        path: "/mon-profile",
      });
    }
    else
    {
      next();
    }



  } else {
    // Si l'utilisateur n'est pas connecté, charger le composant Connexion
    next({
      path: "page-protegee",
      query: { redirect: to.fullPath },
    });
  }
}


let children = []

for (let i = 0; i < Menu.length; i++) {
  children[i] = {
    path : Menu[i].path,
    name : Menu[i].name,
    // eslint-disable-next-line
    component: () => import('Views/'+Menu[i].comp)
  }

  if( Menu[i].caps != null )
  {
    children[i].beforeEnter = function (to, from, next) {
      page_protect(to, from, next)
    }
  }
}




children[children.length] = {
  path : '/groupes/:id',
  name : 'Groupes',
  component: () => import('Views/AdmGroupes'),
  beforeEnter: function (to, from, next) {
    page_protect(to, from, next)
  }
}

children[children.length] = {
  path : 'page-protegee',
  name : '',
  component: () => import('Views/PageProtegee'),
}

children[children.length] = {
  path : 'inscription',
  name : 'inscription',
  component: () => import('Views/Inscription'),
}

children[children.length] = {
  path : 'connexion',
  name : 'Connexion',
  component: () => import('Views/Connexion'),
  beforeEnter: (to, from, next) => {
    let isLoggedIn = store.getters.isLoggedIn;
    if (isLoggedIn) {
      next({
        path: 'ma-famille',
        query: { redirect: to.fullPath },
      });

    } else {
      next();
    }
  }
}

children[children.length] = {
  path : 'confirmez',
  name : 'Confirmez',
  component: () => import('Views/Confirmez'),
  beforeEnter: (to, from, next) => {
    page_protect(to, from, next)
  }
}

children[children.length] = {
  path : 'soutien-scolaire/:slug',
  props: true,
  name : '',
  component: () => import('Views/SoutienScolaireInscription')
}

children[children.length] = {
  path : '/professeurs/:id',
  props: true,
  name : 'teacher-details',
  component: () => import('Views/TeacherDetails')
}

children[children.length] = {
  path : 'confirmation-email/:slug/',
  name : 'Confirmation d\'email',
  component: () => import('Views/Confirmation'),
}

children[children.length] = {
  path : 'nouveau-mot-de-passe/:uid/:token',
  name : 'Nouveau mot de passe',
  component: () => import('Views/NouveauPasse'),
}

/*children[children.length] = {
  path : 'groupes',
  props: true,
  name : 'Groupes',
  component: () => import('Views/AdmGroupes')
}*/

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: children
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  }
})

export default router
