import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';
import quasarUserOptions from './quasar-user-options'
import router from './router'

import store from './store.js'
import storePlugin from './libs/storePlugin'; 

import axios from "axios";

axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'



createApp(App).use(Quasar, {
  plugins: {
    Notify,
    Dialog
  },
  config: {
    notify: quasarUserOptions
  }
}).use(router).use(store).use(storePlugin).mount('#app')
