export default [
	{
		comp: 'MonProfile',
		name: 'Mon profil',
		path: '/mon-profile',
		icon: 'person',
		caps: ['coord', 'parent'],
	},
	{
		comp: 'MaFamille',
		name: 'Ma famille',
		path: '/ma-famille',
		icon: 'diversity_3',
		caps: ['parent'],
	},
	{
		comp: 'MesInscriptions',
		name: 'Mes inscriptions',
		path: '/mes-inscriptions',
		icon: 'card_membership',
		caps: ['parent'],
	},
	{
		comp: 'MonAgenda',
		name: 'Agenda',
		path: '/agenda',
		icon: 'calendar_month',
		caps: ['parent'],
	},
	{
		comp: 'AdmPeriodes',
		name: 'Périodes',
		path: '/periodes',
		icon: 'date_range',
		caps: ['admin'],
	},
	{
		comp: 'AdmAntennes',
		name: 'Antennes',
		path: '/antennes',
		icon: 'store',
		caps: ['admin'],
	},
	{
		comp: 'CoordAntenne',
		name: 'Mon Antenne',
		path: '/mon-antenne',
		icon: 'store',
		caps: ['coord'],
	},
	{
		comp: 'AdmGroupes',
		name: 'Mes Groupes',
		path: '/mes-groupes',
		icon: 'groups_2',
		caps: ['coord'],
	},
	{
		comp: 'AdmSceances',
		name: 'Séances',
		path: '/sceances',
		icon: 'update',
		caps: ['admin', 'coord'],
	},
	{
		comp: 'AdmInscriptions',
		name: 'Inscriptions',
		path: '/inscriptions',
		icon: 'how_to_reg',
		caps: ['admin', 'coord'],
	},
	{
		comp: 'AdmCoordinateurs',
		name: 'Coordinateurs',
		path: '/coordinateurs',
		icon: 'supervised_user_circle',
		caps: ['admin'],
	},
	{
		comp: 'AdmProfesseurs',
		name: 'Professeurs',
		path: '/professeurs',
		icon: 'account_box',
		caps: ['admin', 'coord'],
	},
	{
		comp: 'AdmMembres',
		name: 'Membres',
		path: '/membres',
		icon: 'supervisor_account',
		caps: ['admin', 'coord'],
	},
	{
		comp: 'AdmEtudiants',
		name: 'Etudiants',
		path: '/etudiants',
		icon: 'diversity_1',
		caps: ['admin', 'coord'],
	},
	{
		comp: 'AdmEnvoyerTelecharger',
		name: 'Envoyer / Télécharger',
		path: '/envoyer-telecharger',
		icon: 'cloud',
		caps: ['admin']
	},
	{
		comp: 'AdmGestionFinanciere',
		name: 'Gestion Financière',
		path: '/gestion-financiere',
		icon: 'account_balance',
		caps: ['admin'],
	},
	{
		comp: 'Accueil',
		name: 'Accueil',
		path: '/',
		icon: 'home',
		caps: null,
	},
	{
		comp: 'SoutienScolaire',
		name: 'Soutien scolaire',
		path: '/soutien-scolaire',
		icon: 'school',
		caps: null,
	},
	{
		comp: 'News',
		name: 'News',
		path: '/news',
		icon: 'newspaper',
		caps: null,
	},
	{
		comp: 'Faq',
		name: 'FAQ',
		path: '/faq',
		icon: 'quiz',
		caps: null,
	},
	{
		comp: 'ContactezNous',
		name: 'Contactez-nous',
		path: '/contactez-nous',
		icon: 'info',
		caps: null,
	},
]
